import React from 'react';
import Helmet from 'react-helmet';
import { Layout, Text } from '../../redesign';
import { CheckIcon } from '../../redesign/atoms/icons/check-icon/check-icon';

const SuccessPage: React.FunctionComponent = () => (
  <>
    <Helmet title="Success! | Parsley Health" />
    <Layout>
      <div className="text-center" style={{ minHeight: '30vh' }}>
        <div className="m-lg">
          <CheckIcon iconWidth="100" />
        </div>
        <Text variant="h1a" className="mb-lg">
          Thank You!
        </Text>
        <Text variant="p1a">Your booking has been confirmed.</Text>
        <Text variant="p1a">An invitation has been emailed to you.</Text>
      </div>
    </Layout>
  </>
);

export default SuccessPage;
